<template>
	<div class="map-body">
		<div id="container"></div>
		<div class="sel-device">
			<Input v-model="search_form.store_id" placeholder="请选择" disabled>
				<Button slot="append" @click="$refs['SelStore'].open_modal()">选择商家</Button>
			</Input>
			<Input style="margin-top: 10px;" @on-change="init()" v-model="search_form.search_val" placeholder="设备号/手机号/姓名"></Input>
			<div class="device-list">
				<div class="device-list-item" :class="{'disabled-device-list-item': !item.latitude || !item.longitude}" v-for="(item,index) in data" :key="index" @click="open_info_window(index,true)">
					<div class="device-img">
						<img style="width: 40px;height: 40px;line-height: 40px;font-size: 18px;" src="https://api.cloud.jk125.com/uploads/comm_img/20220824/device_head.png" />
						<Badge :status="item.status == 1 ? 'success' : 'default'"></Badge>
					</div>
					<div style="line-height: 26px; overflow: hidden;">
						<div style="padding-left: 15px;">设备号：{{ item.imei }}</div>
						<div style="padding-left: 15px;" class="ellipsis">用户名：{{ item.u_name || '未绑定用户' }}</div>
						<div style="padding-left: 15px;">手机号：{{ item.u_phone || '未绑定用户' }}</div>
					</div>
				</div>
				<div class="list-null" v-if="data.length == 0">暂无数据</div>
			</div>
			<Page style="text-align: center;" v-model="search_form.page" :page-size="10" simple @on-change="(page) => {this.search_form.page = page;this.init()}" :total="search_form.total"></Page>
		</div>
		<!-- 选择商家 -->
		<SelStore ref="SelStore" @sel-succ="selSucc"></SelStore>
	</div>
</template>

<script>
	var _this
	import AMapLoader from '@amap/amap-jsapi-loader';
	import SelStore from '@/components/common/SelStore.vue'
	import {
		shallowRef
	} from '@vue/reactivity'
	export default {
		components: {
			SelStore
		},
		data() {
			return {
				//地图实例创建者
				AMap: null,
				//查找条件
				search_form: {
					store_name: null, //商户名称
					store_id: null, //商户ID
					page: 1, //当前页码
					search_val: null ,//搜索条件	名称/imei/手机号
					total: 0	//数据总数
				},
				//设备数据
				data: []
			}
		},
		setup() {
			//地图实例
			const map = shallowRef(null);
			return {
				map
			}
		},
		created() {
			_this = this
			this.init()
		},
		methods: {
			//获取设备数据
			init() {
				this.requestApi('/adm/get_device_map',{search_form: this.search_form}).then(
					(res) => {
						if(res.code == 200) {
							_this.data = res.data.data
							_this.search_form.total = res.data.total
							//生产地图
								_this.MapInit()
						}else {
							_this.alertErr(res.msg)
						}
					}
				)
			},
			MapInit() {
				AMapLoader.load({
					key: 'f0a74a4ce476bbf1ff603389e5ff3105',
					version: "2.0",
					plugins: ['AMap.Scale'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				}).then((AMap) => {
					this.AMap = AMap
					this.map = new AMap.Map("container", { //设置地图容器id
						viewMode: "2D", //是否为3D地图模式
						zoom: 5 //初始化地图级别
					});
					//标记点
					let marker_arr = []
					for (let i = 0; i < this.data.length; i++) {
						if (this.data[i].latitude && this.data[i].longitude) {
							let content =
								"<div class='custom-content-marker'>" +
								"<img src='https://www.yiyangiot.com/assets/ic_marker_bg_white.632936f0.png' />" +
								"<img src='https://jk125com-1310001488.cos.ap-nanjing.myqcloud.com/store_13/2022-08-08/1659924688423.png' class='green' />" +
								"<img class='avatar' src='https://jk125com-1310001488.cos.ap-nanjing.myqcloud.com/store_13/2022-08-08/1659923948257.png' />" +
								"</div>"
							var marker = new AMap.Marker({
								position: new AMap.LngLat(this.data[i].longitude, this.data[i]
									.latitude),
								offset: new AMap.Pixel(-23, -55),
								content: content,
								title: '北京',
								zoom: 13
							})
							//监听悬浮事件
							marker.on('mousemove', () => {
								_this.open_info_window(i)
							})
							marker_arr.push(marker)
						}
					}
					this.map.add(marker_arr)
				})
			},
			//打开信息窗口
			open_info_window(index,setFitView_status = false) {
				//获取当前悬浮的内容
				let data = this.data[index];
				//如果是是没有经纬度的则不操作
				if(!data.latitude || !data.longitude){
					return this.alertErr('暂时没有该设备的位置');
				}
				//信息窗口显示内容
				var content =
					"<div class='info-window'>" +
					"<div class='info-window-imei'>imei：" + data.imei + "</div>" +
					"<div class='infow-window-body'>" +
					"<div>商家：" + data.store_name + "</div>" +
					"<div>姓名：" + (data.u_name ? data.u_name : '无') + "</div>" +
					"<div>手机号码：" + (data.u_phone ? data.u_phone : '无') + "</div>" +
					"</div>" +
					"<div class='click-font'>历史数据</div>" +
					"</div>"
				//创建信息窗口实例
				var infoWindow = new this.AMap.InfoWindow({
					offset: new this.AMap.Pixel(0, -55),
					content: content //使用默认信息窗体框样式，显示信息内容
				});
				console.log(data.longitude, data.latitude)
				//打开信息窗口
				infoWindow.open(this.map, [data.longitude, data.latitude]);
				//添加点击事件
				document.getElementsByClassName('click-font')[0].addEventListener('click', () => {
					_this.toUrl('/device/dtl?imei=' + data.imei + '&m_id=' + data.m_id)
				})
				if(setFitView_status) {
					//将地图视角拉向合适位置
					this.map.setZoomAndCenter(23,[data.longitude, data.latitude])
				}
			},
			//选择商家回调
			selSucc(res) {
				this.search_form.store_id = res.store_id
				this.search_form.name = res.name
				this.init()
			}
		}
	}
</script>

<style scoped>
	.map-body {
		position: relative;
	}

	#container {
		padding: 0px;
		margin: 0px;
		width: 100%;
		height: 92vh;
	}

	/deep/ .custom-content-marker {
		position: relative;
	}

	/deep/ .custom-content-marker img {
		width: 45px;
		position: absolute;
		left: 0;
		top: 0;
	}

	/deep/ .custom-content-marker .avatar {
		width: 40px;
		height: 40px;
		left: 2.5px;
		top: 2.5px;
		border-radius: 50%;
	}

	/deep/ .custom-content-marker:hover .green {
		display: block;
	}

	/deep/ .custom-content-marker .green {
		display: none;
	}

	/deep/ .infow-window {
		background-color: #fff;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 10px;
		position: relative;
		color: #333;
		max-width: 300px;
	}

	/deep/ .info-window-imei {
		padding: 10px 0px;
		border-bottom: 1px solid #eee;
		color: red;
		margin-bottom: 6px;
		display: inline-block;
		width: 100%;
		font-size: 14px;
		font-weight: bold;
	}

	/deep/ .click-font {
		color: #00aaff;
		cursor: pointer;
	}

	/deep/ .infow-window-body {
		margin-bottom: 10px;
	}

	.sel-device {
		background-color: #fff;
		padding: 10px;
		display: flex;
		flex-direction: column;
		height: calc(100% - 220px);
		width: 280px;
		position: absolute;
		top: 60px;
		right: 0px;
	}

	.device-list-item {
		display: flex;
		border-bottom: 1px solid #eee;
		align-items: center;
		cursor: pointer;
		position: relative;
	}
	
	.disabled-device-list-item{
		background: #eee;
	}
	
	.device-list {
		border: 1px solid #eee;
		margin-top: 10px;
		flex: 1;
		overflow-y: auto;
		margin-bottom: 10px;
		position: relative;
	}
	
	.not-yet {
		position: absolute;
		right: 0.25rem;
		bottom: 0.25rem;
		font-size: .75rem;
		color: #a8a8a8;
	}
	
	.list-null {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
	.device-img {
		width: 60px;
		min-width: 60px;
	}
	.ellipsis{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
